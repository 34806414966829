body {
  background-color: #f4f5f7 !important;
}

.title_txt {
  font-size: 30px;
}
.header_col_2 {
  text-align: left;
  padding: 10px 0px;
}
.header_col_3 {
  padding: 10px 0px;
}
.card_cont {
  background-color: white;
  border-radius: 10px;
}
.card_border_area {
  padding: 0px !important;
}

.indicator_bg {
  color: #6366f1 !important;
}
.indicator_bg:hover {
  color: black !important;
}

.schedule_interview_btn {
  color: white !important;
  background-color: #6366f1 !important;
  font-weight: bold;
}

.schedule_interview_btn_appbar {
  color: #ffffff !important;
  background-color: #6366f1 !important;
  font-weight: bold;
  margin-right: 1rem;
  border-radius: 200px;
}
.add_icon {
  font-weight: bold !important;
  font-size: 20px !important;
}
.logo_txt {
  color: black !important;
  font-size: 28px !important;
  font-weight: bold !important;
  margin-right: 1rem !important ;
}
.logo_txt:hover {
  color: #6366f1 !important;
}

.logo_img {
  size: 10px !important;
}
.schedule_interview_btn_col {
  text-align: right;
  padding: 10px 0px;
}
