textarea {
  resize: none;
}
.question_text_box {
  padding-top: 6px;
  padding-bottom: 12px;
}
.question_text {
  font-size: 18px;
}
.question_optional {
  font-size: 14px;
  padding-left: 6px;
}
.qest_txt h2 {
  color: white;
}
.search_box {
  text-align: center !important;
}
.question_text_fields_col {
  padding: 100px;
  border-right: solid;
  border-color: #595959;
}
.pre-defined_Q_list_col {
  padding: 100px;
}
.pre-defined_Q_list_col ul {
  list-style: decimal;
  text-align: left;
  font-size: 20px;
  color: black;
  border: solid;
  border-color: #b0b0b0;
  border-radius: 10px;
}
.pre-defined_Q_list_col ul li:hover {
  color: orange;
  cursor: pointer;
  list-style: decimal;
}
.pre-defined_Q_list_col h1 {
  color: orange;
  font-weight: bolder;
}
.question_text_fields_col input {
  width: 100%;
  font-size: 20px;
}
.Q_text {
  font-size: 20px !important;
  color: orange;
  text-align: left;
  margin-top: 10px;
}
.submit_btn {
  color: orange !important;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .qest_txt h2 {
    font-size: 30px;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .qest_txt h2 {
    font-size: 30px;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .qest_txt h2 {
    font-size: 30px;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .qest_txt h2 {
    font-size: 35px;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .qest_txt h2 {
    font-size: 35px;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .qest_txt h2 {
    font-size: 35px;
  }
}
