.interview_title_txt {
  font-size: 20px;
  color: rgb(0, 0, 0);
  margin-left: 49px !important;
  margin-top: 1rem !important;
}
.title_row {
  text-align: left;
}
.total_content_cont {
  border-radius: 10px !important;
}
.video_view_modal_col {
  margin-top: 20px !important;
  text-align: center;
}
.interview_txt_title {
  font-size: 20px;
  margin-top: 4px;
}
.tape_title_txt {
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 1rem;
}
.tape_view_card_2 {
  text-align: -webkit-right;
}
.tape_question_header_txt {
  text-align: left !important;
}
.comments_history {
  text-align: left !important;
}
.comment_icons:hover {
  color: rgb(99, 102, 241);
}
.comment_icons {
  color: black;
}
