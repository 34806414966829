/* .view_btn {
  background-color: rgb(99, 102, 241) !important;
  color: white !important;
  padding: 10px 65px !important;
  margin: 10px;
  border-radius: 25px !important;
} */
/* .video_view_btn {
  text-align: center;
  padding-top: 8px !important;
} */
.video_play_btn {
  background-color: white !important;
  color: #6366f1 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.play_btn_style {
  font-size: 50px !important;
}
.play_btn_img {
  padding-top: 4px;
}
.card_style {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 0.5rem !important;
  cursor: pointer;
}
.card_style:hover {
  border-color: #6366f1;
}
.question {
  font-size: 18px !important;
}
