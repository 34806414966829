.full_card_body {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.card_top_bar {
  background-color: #ffffff;
  font-size: 24px !important;
}
.card_bottom_bar {
  background-color: #ffffff;
  font-size: 24px !important;
  margin-top: 0px !important;
  border-top: 1px solid #cdcdcd;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.card_content {
  margin-top: 0px !important;
  padding: 10px;
  margin-bottom: 0px;
}
.top_bar_item_1 {
  font-weight: normal;
}
.top_bar_item_3 {
  text-align: right;
}
.content_area_col_1 {
  text-align: center !important;
  font-weight: normal;
}

.content_area_col_2 {
  text-align: left !important;
}
.content_area_col_2 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
  font-size: 18px;
  font-weight: normal;
  word-wrap: break-word;
}
.content_area_col_2 ul {
  padding: 0px;
}

.content_area_col_3 {
  text-align: left !important;
}
.content_area_col_3 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
  font-size: 18px;
  font-weight: normal;
}
.content_area_col_3 ul {
  padding: 0px;
}

.content_area_col_4 {
  text-align: left !important;
  margin-top: 1rem !important;
}
.shar_btn {
  background-color: orange !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}
.save_btn {
  background-color: orange !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}
.view_btn {
  background-color: orange !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}

.area_col_4_row_1 {
  text-align: left;
}
.bookmark_icon {
  font-size: 90px !important;
  color: white;
  border-radius: 10px;
}
.container_2 {
  margin: 0px !important;
  padding: 0px !important;
  background-color: black;
  height: 292px;
  text-align: center;
}
.bottom_card_col_2 {
  text-align: right;
}
.view_icon_btn {
  color: black !important;
  border-radius: 10px;
}

.share_icon_btn {
  color: black !important;
  border-radius: 10px;
}

.flag_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.remove_padding {
  padding-left: 0px !important ;
}
.choose_candidate_card_icon {
  color: #6366f1;
}
.select_btn {
  background-color: white !important;
  border: solid 1px #6366f1 !important;
  border-radius: 4px !important;
  color: #6366f1 !important;
  padding: 6px 12px !important;
  font-size: 13.25px !important;
}
.select_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.top_bar_item_2 {
  font-size: 10px;
  padding: 6px;
  text-align: right;
}
.user_status_registered {
  background-color: green !important;
  color: #ffffff !important;
  border: none !important;
}
.user_status_unregistered {
  background-color: orangered !important;
  color: #ffffff !important;
  border: none !important;
}

/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .user_avatar_set_align {
    margin-left: 50px;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .user_avatar_set_align {
    margin-left: 50px;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .user_avatar_set_align {
    margin-left: 50px;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .user_avatar_set_align {
    margin-left: 50px;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .user_avatar_set_align {
    margin-left: 0px;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .user_avatar_set_align {
    margin-left: 0px;
  }
}
