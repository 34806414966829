/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  #justify-tab-example-tab-accepted {
    font-size: 15px !important;
  }
  .home_gif_img {
    display: none !important;
  }
  .empty_space {
    display: none;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .home_gif_img {
    display: none !important;
  }
  .empty_space {
    display: none;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .home_gif_img {
    display: block !important;
  }
  .empty_space {
    display: inline-block;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  #justify-tab-example-tab-accepted {
    font-size: 20px !important;
  }
  .home_gif_img {
    display: block !important;
  }
  .empty_space {
    display: inline-block;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .home_gif_img {
    display: block !important;
  }
  .empty_space {
    display: inline-block;
  }
}
