.new_full_card_body {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
}
.full_card_body_active {
  border: solid 1px #6366f1;
  border-radius: 8px !important;
}

.job_card_container {
  margin-top: 1rem;
  padding-left: 0px !important;
  border-radius: 10px;
}

.status_chip {
  background-color: green !important;
  color: white !important;
  font-weight: normal;
  font-size: 14px !important;
  border: none;
  height: 24px !important;
}
.card_top_bar {
  background-color: #ffffff;
  font-size: 24px !important;
  padding: 0px 4px 0px 24px !important;
}
.new_card_bottom_bar {
  background-color: #ffffff;
  font-size: 15px !important;
  /* margin-top: 0px !important; */
  text-align: left;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #cdcdcd !important;
}

.new_card_content {
  margin-top: 0px !important;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 18px !important;
}
.top_bar_item_3 {
  text-align: right !important;
  padding: 10px 0px !important;
}

.content_area_col_2 {
  text-align: left !important;
}
.content_area_col_2 ul li {
  list-style: none;
}

.content_area_col_1 {
  text-align: left !important;
}
.content_area_col_1 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
  word-wrap: break-word;
}
.content_area_col_1 ul li span {
  color: #6366f1 !important;
}
.content_area_col_1 ul {
  padding: 0px;
}
.content_area_col_3 {
  text-align: left !important;
}
.content_area_col_3 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
}
.content_area_col_3 ul {
  padding: 0px;
}

.content_area_col_4 {
  text-align: left !important;
  margin-top: 1rem !important;
}
.top_bar_item_1 {
  padding: 10px 0px;
  text-align: left;
}

.area_col_4_row_1 {
  text-align: left;
}
.bookmark_icon {
  font-size: 90px !important;
  color: white;
  border-radius: 10px;
}
.container_2 {
  margin: 0px !important;
  padding: 0px !important;
  background-color: black;
  height: 292px;
  text-align: center;
}
.bottom_card_col_2 {
  text-align: right;
}
.view_icon_btn {
  color: black !important;
  padding: 8px !important;
}
.expand_icon_btn {
  color: black !important;
  padding: 8px !important;
}

.share_icon_btn {
  color: black !important;
  border-radius: 10px;
  padding: 8px !important;
}

.flag_icon_btn {
  color: black !important;
  padding: 8px !important;
}

.job_desc_btn {
  color: rgb(99, 102, 241) !important;
  border-radius: 20px !important;
  font-weight: bold !important;
}

.job_desc_btn:hover {
  background-color: rgb(99, 102, 241) !important;
  color: white !important;
}
.jobs_icon_styles {
  color: #6366f1;
}
.skills_txt_card_bottom {
  padding: 6px 0px 6px 0px;
}
.card_botom_skills_txt {
  color: #6366f1;
}
.select_btn {
  background-color: white !important;
  border: solid 1px #6366f1 !important;
  border-radius: 4px !important;
  color: #6366f1 !important;
  padding: 6px 12px !important;
  font-size: 13.25px !important;
}
.select_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.top_bar_item_2 {
  font-size: 10px;
  padding: 6px;
  text-align: right;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .top_bar_item_2 {
    text-align: center;
  }
  .card_top_bar {
    padding: 0px 4px 0px 8px !important;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .bottom_card_col_1 {
    font-size: 15px;
  }
  .top_bar_item_1 {
    font-size: 20px;
  }
  .top_bar_item_2 {
    text-align: center;
  }
  .card_top_bar {
    padding: 0px 4px 0px 8px !important;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .top_bar_item_2 {
    text-align: center;
  }
  .card_top_bar {
    padding: 0px 4px 0px 8px !important;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .top_bar_item_2 {
    text-align: center;
  }
  .card_top_bar {
    padding: 0px 4px 0px 8px !important;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .top_bar_item_2 {
    text-align: right;
  }
  .card_top_bar {
    padding: 0px 4px 0px 24px !important;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .top_bar_item_2 {
    text-align: right;
  }
  .card_top_bar {
    padding: 0px 4px 0px 24px !important;
  }
}
