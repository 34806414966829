.jobs_header {
  text-align: left;
  font-size: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* padding: 1px; */
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.q_area {
  margin-top: 3rem;
}

.question_feild_1 {
  padding-bottom: 1.5rem;
}

.question_feild_2 {
  padding-bottom: 1.5rem;
}
.question_feild_3 {
  padding-bottom: 1.5rem;
}
.pre_questions_col {
  text-align: center;
  font-size: 20px;
  color: rgb(99, 102, 241);
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
}
.question_field_col_1 {
  max-width: 100% !important;
}
.stepper_schedule_btn {
  color: white !important;
  background-color: #6366f1 !important;
  border-radius: 4px !important;
  padding: 8px 22px !important;
  font-size: 13.25px !important;
}
.stepper_schedule_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.search_job_btn {
  background-color: rgb(252, 252, 252) !important;
  margin-left: 1rem;
  color: #6366f1;
  border-radius: 8px !important;
}
.search_job_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
}
.create_button_stepper_1 {
  text-align: right !important;
}
.create_job_btn {
  background-color: rgb(252, 252, 252) !important;
  color: #6366f1 !important;
  margin-top: 6px !important;
  border: 1px solid #6366f1 !important;
  border-radius: 4px !important;
  padding: 8px 22px !important;
  font-size: 13.25px !important;
}
.create_job_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.create_user_btn {
  background-color: rgb(252, 252, 252) !important;
  color: #6366f1 !important;
  margin-top: 20px !important;
  border: 1px solid #6366f1 !important;
  border-radius: 4px !important;
  padding: 8px 22px !important;
  font-size: 13.25px !important;
}
.create_user_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.send_btn_area {
  text-align: center;
}

.step_s {
  display: none;
}
.search_filter_txt {
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.user_search_filter_txt {
  font-weight: bold;
  font-size: 17px;
  text-align: left;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.search_bar {
  background-color: white !important;
  text-align: left !important;
  margin-bottom: 1rem;
}
.total_q_container_1 {
  border-radius: 10px;
  padding: 12px 0px;
  margin-top: 20px;
  text-align: center !important;
  background-color: #ffffff;
}
.total_q_container_2 {
  border-radius: 10px;
  padding: 12px 0px;
  margin-top: 20px;
  background-color: #ffffff;
}

.total_q_container_3 {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 18px;
  margin-top: 20px;
  text-align: left;
  height: auto;
}
.stepper_next_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 13.25px !important;
  padding: 8px 22px !important;
}
.stepper_next_btn:hover {
  background-color: #6366f1 !important;
  color: #ffffff !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.stepper_back_btn {
  background-color: #cdcdcd !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 13.25px !important;
  padding: 8px 22px !important;
}
.stepper_back_btn:hover {
  background-color: #cdcdcd !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.job_row {
  margin-top: 1rem !important;
}

.question_1_row {
  margin-top: 2rem;
}
.question_2_row {
  margin-top: 2rem;
}
.question_3_row {
  margin-top: 2rem;
}
.question_default_col_2 {
  text-align: center !important;
}
.question_1_col_2 {
  text-align: center !important;
}
.question_2_col_2 {
  text-align: center !important;
}
.question_3_col_2 {
  text-align: center !important;
}
.ans_upload_btn {
  border: 1px solid #6366f1 !important;
  padding: 8px 22px !important;
  border-radius: 4px !important;
  color: #6366f1 !important;
  font-size: 13.25px !important;
}
.ans_upload_btn:hover {
  background-color: #6366f1 !important;
  color: #ffffff !important;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.choose_candidate_grid_item {
  margin-top: 1rem !important;
}
.ques_txt {
  margin-bottom: 8px !important;
  font-size: 15px;
  color: #6366f1;
  font-weight: bold;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .create_button_stepper_1 {
    text-align: center !important;
    padding-top: 14px;
  }
  .create_button_stepper_2 {
    text-align: center !important;
  }
  .search_filter_txt {
    text-align: center;
  }
  .jobs_header {
    text-align: center;
  }
  .user_search_filter_txt {
    text-align: center;
  }
  .question_default_col_2 {
    padding: 10px 0px;
  }
  .question_1_col_2 {
    padding: 10px 0px;
  }
  .question_2_col_2 {
    padding: 10px 0px;
  }
  .question_3_col_2 {
    padding: 10px 0px;
  }
  .question_1_row {
    margin-top: 0.5rem;
  }
  .question_2_row {
    margin-top: 0.5rem;
  }
  .question_3_row {
    margin-top: 0.5rem;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .create_button_stepper_1 {
    text-align: center !important;
    padding-top: 14px;
  }
  .create_button_stepper_2 {
    text-align: center !important;
  }
  .search_filter_txt {
    text-align: center;
  }
  .jobs_header {
    text-align: center;
  }
  .user_search_filter_txt {
    text-align: center;
  }
  .question_default_col_2 {
    padding: 10px 0px;
  }
  .question_1_col_2 {
    padding: 10px 0px;
  }
  .question_2_col_2 {
    padding: 10px 0px;
  }
  .question_3_col_2 {
    padding: 10px 0px;
  }
  .question_1_row {
    margin-top: 0.5rem;
  }
  .question_2_row {
    margin-top: 0.5rem;
  }
  .question_3_row {
    margin-top: 0.5rem;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .create_button_stepper_1 {
    text-align: center !important;
    padding-top: 14px;
  }
  .create_button_stepper_2 {
    text-align: center !important;
  }
  .search_filter_txt {
    text-align: center;
  }
  .jobs_header {
    text-align: center;
  }
  .user_search_filter_txt {
    text-align: center;
  }
  .question_default_col_2 {
    padding: 10px 0px;
  }
  .question_1_col_2 {
    padding: 10px 0px;
  }
  .question_2_col_2 {
    padding: 10px 0px;
  }
  .question_3_col_2 {
    padding: 10px 0px;
  }
  .question_1_row {
    margin-top: 0.5rem;
  }
  .question_2_row {
    margin-top: 0.5rem;
  }
  .question_3_row {
    margin-top: 0.5rem;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .create_button_stepper_1 {
    text-align: center !important;
    padding-top: 14px;
  }
  .search_filter_txt {
    text-align: center;
  }
  .jobs_header {
    text-align: center;
  }
  .user_search_filter_txt {
    text-align: center;
  }
  .question_default_col_2 {
    padding: 10px 0px;
  }
  .question_1_col_2 {
    padding: 10px 0px;
  }
  .question_2_col_2 {
    padding: 10px 0px;
  }
  .question_3_col_2 {
    padding: 10px 0px;
  }
  .question_1_row {
    margin-top: 0.5rem;
  }
  .question_2_row {
    margin-top: 0.5rem;
  }
  .question_3_row {
    margin-top: 0.5rem;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .create_button_stepper_1 {
    text-align: right !important;
    padding-top: 0px;
  }
  .create_button_stepper_2 {
    text-align: right !important;
  }
  .search_filter_txt {
    text-align: left;
  }
  .jobs_header {
    text-align: left;
  }
  .user_search_filter_txt {
    text-align: left;
  }
  .question_default_col_2 {
    padding: 0px 0px;
  }
  .question_1_col_2 {
    padding: 0px 0px;
  }
  .question_2_col_2 {
    padding: 0px 0px;
  }
  .question_3_col_2 {
    padding: 0px 0px;
  }
  .question_1_row {
    margin-top: 2rem;
  }
  .question_2_row {
    margin-top: 2rem;
  }
  .question_3_row {
    margin-top: 2rem;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .create_button_stepper_1 {
    text-align: right !important;
    padding-top: 0px;
  }
  .create_button_stepper_2 {
    text-align: right !important;
  }
  .search_filter_txt {
    text-align: left;
  }
  .jobs_header {
    text-align: left;
  }
  .user_search_filter_txt {
    text-align: left;
  }
  .question_default_col_2 {
    padding: 0px 0px;
  }
  .question_1_col_2 {
    padding: 0px 0px;
  }
  .question_2_col_2 {
    padding: 0px 0px;
  }
  .question_3_col_2 {
    padding: 0px 0px;
  }
  .question_1_row {
    margin-top: 2rem;
  }
  .question_2_row {
    margin-top: 2rem;
  }
  .question_3_row {
    margin-top: 2rem;
  }
}
