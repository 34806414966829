.card {
  text-align: center;
  padding: 6px 12px;
}
.profile_info_page {
  padding: 6px 12px;
}
.mb-3 h5 {
  color: #6366f1;
}
.profile_pic_btn {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: white !important;
  padding: 9px 12px;
  color: #6366f1;
  border-color: #6366f1;
  border: 1px solid !important;
  border-radius: 5px;
}

/* .profile_pic_btn:hover{
    background-color:#6366f1 !important;
    color: white !important;
    border: #6366f1 !important;
    border: 1px solid !important;
} */
.profile_pic_btn_html {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: white !important;
  border: 1px solid #6366f1 !important;
  padding: 9px 12px;
  border-radius: 5px;
  color: #6366f1;
  font-size: 18px;
}
.choose_profile_pic {
  margin-bottom: 1rem;
}
/* .profile_pic_btn_html:hover{
    background-color:#6366f1 !important;
    color: white !important;
    border: #6366f1 !important;
    border: 1px solid !important;
} */
.continue_btn {
  background-color: white !important;
  border: none;
  color: #6366f1;
  border: 1px solid #6366f1;
}
.continue_btn:hover {
  background-color: #6366f1 !important;
  color: white;
  border: 1px solid #6366f1;
}
.save_btn {
  text-align: center;
}
.profile_details {
  border: 1px solid;
  border-color: gainsboro;
  border-radius: 6px;
  background-color: white;
  padding: 6px 12px;
}
.select_fresher {
  padding: 6px 12px;
}
.select_experienced {
  padding: 6px 12px;
}
.error_msg {
  color: red !important;
  margin: 0px !important;
}
.profile_editInfo_text {
  background-color: #595959 !important;
  color: white;
  text-align: center;
  height: 85px;
}
.profile_editInfo_text h2 {
  margin-top: 1.5rem;
  color: white;
}
#qualification {
  display: block;
  padding: 6px 12px;
  width: 100%;
  border-color: #ced4da;
  border-radius: 0.374rem;
}
.edit_info {
  text-align: center;
}
.profile_pic {
  border-radius: 8px;
  background-image: url(../../../src/assets/images/profile_dummy.jpg) !important;
  background-repeat: no-repeat;
}

.profile_pic_card {
}
.termsandpolicy_txt a {
  color: #6366f1;
}
#formGridCheckbox1 {
  display: flex;
}
.termsandpolicy_txt_margin {
  margin-bottom: 1rem;
}
#formHorizontalRadios4 {
  visibility: hidden;
}
#formHorizontalRadios5 {
  visibility: hidden;
}
#formHorizontalRadios6 {
  visibility: hidden;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
