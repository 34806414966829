.register_title_txt h3 {
  color: #6366f1 !important;
}
.register_box {
  text-align: center;
}
.S9gUrf-YoZ4jf {
  display: inline-flex;
}
.register_btn {
  font-size: 17px !important;
  color: white !important;
  padding: 12px 12px !important;
  width: 100%;
  margin-bottom: 1rem !important;
  background-color: #6366f1 !important;
  border: none !important;
  margin-top: 1rem !important;
  font-weight: bold !important;
}

.register_btn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.register_login_txt {
  color: black;
  text-decoration: none;
}
.register_login_txt:hover {
  color: black;
  text-decoration: none;
}
h5 {
  display: flex;
  flex-direction: row;
}
h5:before,
h5:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
h5:before {
  margin-right: 10px;
}
h5:after {
  margin-left: 10px;
}

.signin_txt {
  color: black !important;
}
.verify_txt p {
  text-align: center;
  font-size: 17px;
}
.termsandpolicy_txt {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
