/*--------------Home-----------------*/

@media only screen and (min-width: 200px) {
}
@media only screen and (min-width: 320px) {
  .login_title_txt {
    display: none !important;
  }
  .login-card {
    display: none;
  }
}
@media only screen and (min-width: 480px) {
  .login_title_txt {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .login_title_txt {
    display: block !important;
  }
  .login-card {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .login-card {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .login-card {
    display: block;
  }
}
/*--------------Home-----------------*/
