.employee_details_txt {
  background-color: black;
  height: 85px !important;
  text-align: center;
}
.employee_details_txt h2 {
  margin-top: 1.5rem !important;
  color: white;
}
.user_profile_details {
  padding: 12px 24px;
  margin: 1rem;
}
.profile_details_row {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 6px 12px;
  border-radius: 10px;
}
.profile_pic_col {
  padding: 24px 24px;
}
.profile_pic_col img {
  border-radius: 20px;
  width: 100%;
  margin-top: 0px !important;
}
.details_list ul li {
  list-style: none;
  padding: 6px 8px;
}
.details_list ul {
  padding-left: 0px;
}
.details_list span {
  padding: 4px 8px;
}
.emp_details_row {
  padding: 6px 12px;
}
.emp_details_col {
  margin-top: 2rem;
}
.emp_details_btn {
  background-color: white !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: normal !important;
  color: orange !important;
  border-color: orange;
}
.emp_details_btn:hover {
  border: solid 1px orange !important;
}
.linkedin_btn {
  background-color: #007db7 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px;
  margin: 4px;
}

.linkedin_btn a {
  color: white;
  text-decoration: none;
}
.github_btn {
  background-color: #444444 !important;
  color: white !important;
  border: none !important;
  margin: 3px;
  border-radius: 20px;
  margin: 4px;
}
.btn_links {
  padding: 6px 12px;
}
.mb-3 {
  padding: 0px;
}
.active {
  font-size: 15px;
  text-decoration: none;
}

.thumbnail_container img {
  position: relative;
  text-align: center;
  color: white;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.bg-text h2 {
  color: white;
  font-size: 25px;
}

.view_btn {
  background-color: white !important;
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: orange !important;
  border-color: orange;
}
.view_btn:hover {
  background-color: orange !important;
  color: black !important;
  border-color: transparent;
}

.card_content {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin: 5px;
  margin-bottom: 10px;
}
.card {
  margin-bottom: 1rem;
}
.card-footer {
  background-color: white;
}
.company_details {
  text-align: left;
}
.company_details ul li {
  list-style: none;
  padding: 6px 8px;
  color: black;
}
.respond_btns {
  text-align: center;
  padding: 2px;
}
.card_title {
  color: black;
}
.pendning_card_view_btn {
  background-color: rgb(255, 255, 255) !important;
  border: none;
  font-size: larger;
  color: orange;
  font-weight: bolder;
  padding-left: 28px !important;
  padding-right: 28px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pendning_card_view_btn:hover {
  background-color: orange !important;
  color: rgb(0, 0, 0);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pendning_card_Accept_btn {
  background-color: orange !important;
  border: none;
  font-size: larger;
  color: black;
  font-weight: bolder;
  padding-left: 18px;
  padding-right: 18px;
}
.pendning_card_Accept_btn:hover {
  color: rgb(255, 255, 255);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pendning_card_Decline_btn {
  background-color: orange !important;
  border: none;
  font-size: larger;
  color: black;
  font-weight: bolder;
}
.pendning_card_Decline_btn:hover {
  background-color: orange !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card_company_name {
  font-size: 30px;
}

.card_company_email_name {
  font-size: 20px;
}

.card_company_Phone_no {
  font-size: 18px;
}

.nav-link {
  color: black;
  font-weight: bold;
}
.active {
  color: orange !important;
}
.edit_profile_txt {
  text-align: right;
}

.edit_profile_txt li :hover {
  color: orange !important;
}
.mb-3 Tab {
  border: none !important;
}
.profile_edit_icon {
  color: black;
}
a {
  text-decoration: none;
}
