/*
  https://developer.mozilla.org/en/docs/Web/CSS/box-shadow
  box-shadow: [inset?] [top] [left] [blur] [size] [color];

  Tips:
    - We're setting all the blurs to 0 since we want a solid fill.
    - Add the inset keyword so the box-shadow is on the inside of the element
    - Animating the inset shadow on hover looks like the element is filling in from whatever side you specify ([top] and [left] accept negative values to become [bottom] and [right])
    - Multiple shadows can be stacked
    - If you're animating multiple shadows, be sure to keep the same number of shadows on hover/focus as non-hover/focus (even if you have to create a transparent shadow) so the animation is smooth. Otherwise, you'll get something choppy.
*/

// Animate the size, inside
.fill:hover,
.fill:focus {
  box-shadow: inset 0 0 0 2em var(--hover);
}

// Animate the size, outside
.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

// Stack multiple shadows, one from the left, the other from the right
.close:hover,
.close:focus {
  box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
}

// Size can also be negative; see how it's smaller than the element
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

// Animating from the bottom
.up:hover,
.up:focus {
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

// And from the left
.slide:hover,
.slide:focus {
  box-shadow: inset 10rem 0 0 0 var(--hover);
}

// Multiple shadows, one on the outside, another on the inside
.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
  }
}

//=== Set button colors
// If you wonder why use Sass vars or CSS custom properties...
// Make a map with the class names and matching colors
$colors: (
  fill: #a972cb,
  pulse: #ef6eae,
  close: #ff7f82,
  raise: #ffa260,
  up: #e4cb58,
  slide: #6366f1,
  offset: #19bc8b,
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}

// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
button {
  color: var(--color);
  transition: 0.5s;
}

//=== Pen styling, ignore

// Basic button styles
button {
  background: none;
  border: 1px solid;
  font: inherit;
  line-height: 1;
  font-size: 20px;
  margin: 0.5em;
  padding: 8px 12px;
  border-radius: 8px;
}

.slide {
  --hover: #6366f1;
}

/*-----Accept----------*/

// Animate the size, inside
.fill:hover,
.fill:focus {
  box-shadow: inset 0 0 0 2em var(--hover);
}

// Animate the size, outside
.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

// Stack multiple shadows, one from the left, the other from the right
.close:hover,
.close:focus {
  box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
}

// Size can also be negative; see how it's smaller than the element
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

// Animating from the bottom
.up:hover,
.up:focus {
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

// And from the left
.slide_accept:hover,
.slide_accept:focus {
  box-shadow: inset 20rem 0 0 0 var(--hover);
}

// Multiple shadows, one on the outside, another on the inside
.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
  }
}

//=== Set button colors
// If you wonder why use Sass vars or CSS custom properties...
// Make a map with the class names and matching colors
$colors: (
  fill: #a972cb,
  pulse: #ef6eae,
  close: #ff7f82,
  raise: #ffa260,
  up: #e4cb58,
  slide_accept: #6366f1,
  offset: #19bc8b,
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}

// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
button {
  color: var(--color);
  transition: 0.5s;
}

//=== Pen styling, ignore

// Basic button styles

.slide_accept {
  --hover: green;
  .slide_accept {
    --hover: green;
  }
  /*-----Accept----------*/

  /*-----Decline----------*/
  // Animate the size, inside
  .fill:hover,
  .fill:focus {
    box-shadow: inset 0 0 0 2em var(--hover);
  }

  // Animate the size, outside
  .pulse:hover,
  .pulse:focus {
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--hover);
    }
  }

  // Stack multiple shadows, one from the left, the other from the right
  .close:hover,
  .close:focus {
    box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
  }

  // Size can also be negative; see how it's smaller than the element
  .raise:hover,
  .raise:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
  }

  // Animating from the bottom
  .up:hover,
  .up:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover);
  }

  // And from the left
  .slide_decline:hover,
  .slide_decline:focus {
    box-shadow: inset 20rem 0 0 0 var(--hover);
  }

  // Multiple shadows, one on the outside, another on the inside
  .offset {
    box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

    &:hover,
    &:focus {
      box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
    }
  }

  //=== Set button colors
  // If you wonder why use Sass vars or CSS custom properties...
  // Make a map with the class names and matching colors
  $colors: (
    fill: #a972cb,
    pulse: #ef6eae,
    close: #ff7f82,
    raise: #ffa260,
    up: #e4cb58,
    slide_decline: #6366f1,
    offset: #19bc8b,
  );

  // Sass variables compile to a static string; CSS variables are dynamic and inherited
  // Loop through the map and set CSS custom properties using Sass variables
  @each $button, $color in $colors {
    .#{$button} {
      --color: #{$color};
      --hover: #{adjust-hue($color, 45deg)};
    }
  }

  // Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
  button {
    color: var(--color);
    transition: 0.5s;
  }

  //=== Pen styling, ignore

  // Basic button styles
}
