.bg_color {
  background-color: #f4f5f7;
  height: 900px;
}
.bg_color_context {
  background-color: white;
}
.edit_profile_title_txt {
  font-size: 22px;
}

.edit_info_container {
  border-radius: 8px !important;
  padding-right: 70px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  margin-top: 3rem;
}
.tabs_style {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 8px !important;
  background-color: white;
  height: 525px;
  text-align: left !important;
}
.input_field_style {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 8px !important;
  background-color: white;
  height: auto;
  width: 100%;
}
.tabs_list_grid {
  position: relative !important;
}
.avatar_style {
  margin-top: 1.5rem !important;
  margin-left: 2.25rem !important;
  margin-right: auto !important;
  padding-bottom: 0px !important;
  position: absolute !important;
}

.submit_btn {
  text-align: center !important;
}
.profile_save_btn {
  background-color: #6366f1 !important;
  color: white !important;
}
.profile_next_btn {
  background-color: #6366f1 !important;
  color: white !important;
}

.cancel_btn {
  background-color: grey !important;
  color: white !important;
  margin-left: 1rem !important;
}
.sub_head_txt {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
}
.tab_list_style {
  min-height: 45px !important;
  justify-content: flex-start !important;
}
.camera_icon_overlay {
  position: relative !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: 150px;
  left: 160px;
  background-color: white !important;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .grid_item_mob_view {
    display: none !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 5.5rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 200px;
    background-color: white !important;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .grid_item_mob_view {
    display: none !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 5.5rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 200px;
    background-color: white !important;
  }
  .tab_list_style {
    min-height: 45px !important;
    justify-content: center !important;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .grid_item_mob_view {
    display: none !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 5.5rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 200px;
    background-color: white !important;
  }
  .tab_list_style {
    min-height: 45px !important;
    justify-content: center !important;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .grid_item_mob_view {
    display: none !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 14rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 325px;
    background-color: white !important;
  }
  .tab_list_style {
    min-height: 45px !important;
    justify-content: flex-end !important;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .grid_item_mob_view {
    display: block !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 2.75rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 160px;
    background-color: white !important;
  }
  .tab_list_style {
    min-height: 45px !important;
    justify-content: flex-start !important;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .grid_item_mob_view {
    display: block !important;
  }
  .avatar_style {
    margin-top: 1.5rem !important;
    margin-left: 2.75rem !important;
    margin-right: auto !important;
  }
  .camera_icon_overlay {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 150px;
    left: 160px;
    background-color: white !important;
  }
  .tab_list_style {
    min-height: 45px !important;
    justify-content: flex-start !important;
  }
}
