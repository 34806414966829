.view_jobs_txt {
  background-color: #595959;
  height: 85px;
  text-align: center;
}
.view_jobs_txt h2 {
  color: white;
  margin-top: 1.5rem;
}
.job_details_container {
  border: solid 1px;
  margin-top: 1rem;
  border-radius: 9px;
  margin-bottom: 1rem;
}
.job_details_container ul li {
  list-style: none;
  padding-top: 25px;
  font-size: 20px;
}
.hold_job_btn {
  color: grey;
  width: 100%;
}

.close_job_btn {
  color: red;
  width: 100%;
}
.job_details_col_3 {
  text-align: right;
  padding-top: 10px;
}
.schedule_jobpage_btn {
  width: 100%;
  color: white;
  margin-top: 4.5rem;
  background-color: rgb(99, 102, 241);
  box-shadow: #595959;
}
.schedule_jobpage_btn:hover {
  color: rgb(99, 102, 241);
  background-color: white;
}
.job_edit_icon {
  list-style: none;
  padding-top: 8px;
}
.job_details_col_4 {
  text-align: right;
}

.edit_job_icon {
  size: 20px !important;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
}
