nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 6px 12px;
  z-index: 999;
}
#navbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  transition: 0.3s ease-in-out;
}
#navbar li a:hover,
#navbar li a.active {
  color: rgb(99, 102, 241);
}

#navbar li a:hover::after,
#navbar li a.active::after {
  content: '';
  width: 50%;
  height: 2px;
  background: rgb(99, 102, 241);
  position: absolute;
  bottom: -4px;
  left: 20px;
}
#navbar .nav_logout_btn.active {
  color: black !important;
}

#mobile {
  display: none;
}

#mobile i {
  color: rgb(99, 102, 241);
  align-items: right;
}
.active {
  color: rgb(99, 102, 241);
  font-size: 27px;
}

.title_txt a {
  color: black;
  font-size: larger;
  font-weight: bolder;
  text-decoration: none;
  font-size: 30px;
}
.title_txt a:hover {
  color: rgb(99, 102, 241);
}

.nav_logout_btn {
  display: none;
}
.nav_logout_icon {
  padding: 0px !important;
}
.nav_signup_btn {
  background-color: rgb(99, 102, 241);
  color: white;
  border: none;
  padding: 8px 20px;
  font-weight: bold;
}
.nav_signup_btn:hover {
  background-color: rgb(132, 100, 238);
  color: white;
  border: none;
}
.nav_signup_btn:active {
  background-color: rgb(67, 70, 241) !important;
  color: white !important;
  border: none !important;
}
.nav_display_inline {
  display: inline-flex;
}
.right_col_items {
  display: flex;
}
.app_name:hover {
  color: rgb(99, 102, 241) !important;
}
.app_name {
  margin-left: 1rem;
}
.tape_txt {
  color: rgb(99, 102, 241);
}
.account_icon {
  font-size: 30px !important;
  color: rgb(99, 102, 241);
}

.account_Icon_btn {
  padding: 0px !important;
  margin: 0px !important;
}

@media screen and (max-width: 769px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: #ffffff;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 0px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
    z-index: 999;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: block;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
  .nav_logout_btn {
    display: block;
  }
  nav {
    padding: 20px 12px;
  }
}
