.set_bg_color {
  background-color: white;
}
.chooseprofile_txt_col {
  margin-top: 2rem;
  padding: 50px 50px;
  margin-left: 5rem;
}
.chooseprofile_txt_col h2 {
  color: black;
  font-weight: bold;
  text-align: left;
}
.name_txt {
  color: #6366f1;
  font-size: 35px;
  font-weight: bold;
}
.content_txt {
  color: #6366f1;
  font-size: 18px;
  font-weight: normal;
}
.choose_role_card_style {
  border-radius: 8px !important;
}
.choose_role_card_style:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #6366f1 !important;
  border: 1px solid #6366f1 !important;
}

.recruiter_card_txt {
  margin-top: 2rem;
  font-size: 25px;
  text-transform: none;
}
.candidate_btn_style {
  width: 100%;
  background: white;
  color: black;
  border: 1px solid #cdcdcd !important;
  padding: 15px 0px;
  border-radius: 8px;
}

.candidate_card_txt {
  font-size: 25px !important;
  text-transform: none;
  margin-top: 2rem;
}

/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .chooseprofile_txt_col {
    margin-top: 0rem;
    padding: 25px 15px;
    margin-left: 0rem;
  }
  .choose_profile_gif {
    display: none;
  }

  .choose_profile_btn_padding {
    padding-left: 4px;
    padding-right: 4px;
  }
  .recruiter_btn_txt {
    font-size: 22px;
    text-align: center;
  }
  .candidate_btn_txt {
    font-size: 22px;
    text-align: center;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .chooseprofile_txt_col {
    margin-top: 0rem;
    padding: 25px 15px;
    margin-left: 0rem;
  }
  .choose_profile_gif {
    display: none;
  }

  .choose_profile_btn_padding {
    padding-left: 4px;
    padding-right: 4px;
  }
  .recruiter_btn_txt {
    font-size: 22px;
    text-align: center;
  }
  .candidate_btn_txt {
    font-size: 22px;
    text-align: center;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .chooseprofile_txt_col {
    margin-top: 0rem;
    padding: 25px 15px;
    margin-left: 0rem;
  }
  .choose_profile_gif {
    display: none;
  }

  .choose_profile_btn_padding {
    padding-left: 4px;
    padding-right: 4px;
  }
  .recruiter_btn_txt {
    font-size: 22px;
    text-align: center;
  }
  .candidate_btn_txt {
    font-size: 22px;
    text-align: center;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .chooseprofile_txt_col {
    margin-top: 0rem;
    padding: 25px 15px;
    margin-left: 0rem;
  }
  .choose_profile_gif {
    display: none;
  }

  .choose_profile_btn_padding {
    padding-left: 4px;
    padding-right: 4px;
  }
  .recruiter_btn_txt {
    font-size: 22px;
    text-align: center;
  }
  .candidate_btn_txt {
    font-size: 22px;
    text-align: center;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .chooseprofile_txt_col {
    margin-top: 2rem;
    padding: 50px 50px;
    margin-left: 5rem;
  }
  .choose_profile_gif {
    display: block;
  }

  .choose_profile_btn_padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .recruiter_btn_txt {
    font-size: 25px;
    text-align: left;
  }
  .candidate_btn_txt {
    font-size: 25px;
    text-align: left;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .chooseprofile_txt_col {
    margin-top: 2rem;
    padding: 50px 50px;
    margin-left: 5rem;
  }
  .choose_profile_gif {
    display: block;
  }

  .choose_profile_btn_padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .recruiter_btn_txt {
    font-size: 25px;
    text-align: left;
  }
  .candidate_btn_txt {
    font-size: 25px;
    text-align: left;
  }
}
