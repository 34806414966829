.video_rec_options {
  margin: 30px;
}
.qest_txt {


}
.Q_txt {
  text-align: left;
}
.qest_txt h2 {
  margin-top: 1rem !important;
  color: rgb(0, 0, 0) !important;
  font-weight: 300;
font-size: 30px !important;
}
.open {
  background-color: orange;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
}
.record_body {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.media_position {
  text-align: center;
}
.video_position {
  margin-top: 1rem;
}
.video_position video {
  border-radius: 15px;
}
.video_bottom_txt {
  margin-bottom: 1rem;
}
.video_bottom_txt Button {
  background-color: white;
  color: orange;
  border: 1px solid orange;
}
.video_bottom_txt Button:hover {
  background-color: orange;
  color: white;
  border: 1px solid orange;
}
.close {
  background-color: rgb(255, 0, 0);
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  color: white;
}
.startRecord {
  background-color: rgb(255, 0, 0);
  border: none;
  border-radius: 40px;
  padding: 15px !important;
  margin: 10px;
  color: white;
  font-size: 50px !important;
}
.ans_heading_txt {
  font-size: 20px;
  font-weight: bolder;
  color: black;
  margin-bottom: 20px;
}
.Q_txt {
  padding: 20px;
  font-size: 20px;
  font-weight: 400;
}
.tab_view {
  display: none;
}
.record_video_view {
  border: 1px solid grey;
  border-radius: 8px;
  text-align: center;
}
.video_screen_size {
  padding: 12px 24px;
}
.tips_txt {
  font-size: 16px;
  font-weight: normal;
}
.tips_txt p {
  margin: 0px;
  margin-left: 0.75rem;
  font-weight: bold;
  margin-top: 0.75rem;
  color: orange;
}
.zero_padding {
  padding: 0px;
}
.video_height {
  height: 500px;
}
.video_edit {
  border-radius: 8px;
}
/*------------BUTTON CSS---------------*/

.demo-section {
  padding-bottom: 2rem;
  border-bottom: 2px solid;
}
.demo-section:last-child {
  border: none;
}
.Registration {
  background-color: sienna;
  border-color: crimson;
  font-weight: 100;
}
.open {
  background-color: skyblue; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.close {
  background-color: orange; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.startRecord {
  background-color: forestgreen; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.startRecord:hover {
  color: black !important;
}
.stopRecord {
  background-color: red; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: rgb(255, 0, 0);
  border: none;
  padding: 15px !important;
  margin: 10px;
  color: white;
  font-size: 50px !important;
}
.retakeRecord {
  background-color: olive; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  background-color: rgb(21, 0, 255);
  border: none;
  border-radius: 40px;
  padding: 15px !important;
  margin: 10px;
  color: white;
  font-size: 50px !important;
}
.downloadRecord {
  background-color: olive; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

/*------------BUTTON CSS---------------*/

/*------------NEW BUTTON CSS---------------*/

.jODozX {
  margin: 20px !important;
}

/*------------NEW BUTTON CSS---------------*/
