.rec_create_profile {
  font-size: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
}
.rec_create_profile_grid {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 8px !important;
  background-color: white;
  height: auto;
  margin-left: 1rem !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.rec_create_save_btn_grid {
  text-align: center;
}
.rec_create_save_btn {
  background-color: #6366f1 !important;
  color: white !important;
}
