.share_options_btn {
  color: white !important;
  background-color: rgb(99, 102, 241) !important ;
}
.dialog_title {
  color: black !important;
  font-size: 20px !important;
}
.share_option_view_icon {
  background-color: rgb(99, 102, 241) !important;
  color: white !important ;
}
.copylink_option_view_icon {
  background-color: rgb(99, 102, 241) !important;
  color: white !important ;
}
.icon_grid {
  padding-top: 15px !important;
  padding-left: 10px;
}
.details_txt {
  font-weight: lighter;
  font-size: 13px;
  color: gray;
}
.dialog_title_txt {
  font-weight: bold;
  font-size: 16px;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}

/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
}

/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
}
