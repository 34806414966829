.note_container {
  margin: 1rem;
}

.note_title_txt h2 {
  color: black;
  margin-top: 5px;
  margin-bottom: 5px !important;
  text-align: left;
  font-size: 30px;
  font-weight: lighter;
}
.note_instruction_txt {
  border: 1px solid grey;
  height: auto;
  border-radius: 15px;
}
.note_instruction_txt h3 {
  text-align: center;
  margin-top: 0.5rem;
  color: #6366f1 !important;
}
.note_row {
  padding: 12px 24px;
}
.note_txt {
  font-size: 20px;
  font-weight: bold;
}
.note_list_txt li {
  padding: 6px 12px;
  font-size: 18px;
}
.note_btn_row {
  text-align: center !important;
}
