.set_grid_bg_color {
  background-color: white !important;
}
.login_title_txt h3 {
  color: #6366f1;
  font-size: 20px;
}
.login_btn {
  background-color: #6366f1 !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 17px !important;
  font-weight: bold !important;
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem !important;
}
.login_btn:hover {
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.login_signup_txt {
  color: black;
  text-decoration: none;
}
.login_signup_txt:hover {
  text-decoration: none;
  color: black;
}

.login-card {
  margin: 1rem;
  box-shadow: none !important;
}
.css-1lymokj {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}
.signup_btn {
  background-color: rgb(255, 255, 255) !important;
  border: solid 2px !important;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 17px !important;
  font-weight: bold !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  color: #6366f1 !important;
}
.signup_btn:hover {
  background-color: #6366f1 !important;
  color: rgb(255, 255, 255) !important;
}
.login_text_field {
  background-color: white !important;
}
.forgot_txt {
  color: #6366f1 !important;
}
.login_back_btn {
  background-color: rgb(255, 255, 255) !important;
  border: solid 2px !important;
  box-shadow: none !important;
  font-size: 17px !important;
  font-weight: bold !important;
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  color: #6366f1 !important;
}

.login_back_btn:hover {
  background-color: white !important;
  color: #6366f1 !important;
}
.resend_btn {
  background-color: #6366f1 !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 17px !important;
  font-weight: bold !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
