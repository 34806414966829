.full_card_body {
  margin-top: 10px !important;
  border: solid transparent;
  background-color: white;
  border-radius: 10px;
}
.full_card_body:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px !important;
}
/* .full_card_body:hover .card_bottom_bar {
    background-color: #6366F1;
    transition: 0.3s;
    transition-duration: 1s;
  } */
/* .full_card_body:hover {
    border: solid #6366F1;
    transition: 0.3s;
  } */

.card_top_bar {
  background: rgb(249, 249, 247) !important;
  background: linear-gradient(
    90deg,
    rgb(249, 247, 249) 0%,
    rgb(208, 207, 208) 29%,
    rgba(218, 218, 218, 1) 72%,
    rgba(250, 250, 250, 1) 100%
  ) !important;
  font-size: 24px !important;
}
.card_bottom_bar {
  background-color: #ffffff;
  font-size: 24px !important;
  margin-top: 0px !important;
  padding: 10px;
}
.card_bottom_bar:hover {
}

.card_content {
  margin-top: 0px !important;
  padding: 10px;
  margin-bottom: 0px;
}
.top_bar_item_3 {
  text-align: right;
}
.content_area_col_1 {
  text-align: center !important;
}

.content_area_col_2 {
  text-align: left !important;
}
.content_area_col_2 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
}
.content_area_col_2 ul {
  padding: 0px;
}

.content_area_col_3 {
  text-align: left !important;
}
.content_area_col_3 ul li {
  list-style: none;
  padding-bottom: 12px;
  padding-top: 4px;
}
.content_area_col_3 ul {
  padding: 0px;
}
.shar_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}
.save_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}
.view_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
}

.area_col_4_row_1 {
  text-align: left;
}
/* .bookmark_icon:hover {
      background-color: #6366F1;
      border-radius: 20px;
      transition: 0.3s;
    } */
.bookmark_icon {
  font-size: 90px !important;
  color: white;
  border-radius: 10px;
}
.container_2 {
  margin: 0px !important;
  padding: 0px !important;
  background-color: black;
  height: 292px;
  text-align: center;
}
.bottom_card_col_2 {
  text-align: right;
}
.view_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.view_icon_btn:hover {
  color: #6366f1 !important;
}

.flag_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.flag_icon_btn:hover {
  color: #6366f1 !important;
}
.skill_tags {
  color: #6366f1;
  font-size: 20px;
  font-style: italic;
}

.content_area_col_4 {
  text-align: right !important;
  margin-top: 10px;
}
.menu_icon_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 40px !important;
  border: 1px solid transparent !important;
  size: 20px !important;
}
.menu_icon_btn:hover {
  color: #6366f1 !important;
  background-color: white !important;
  border: 1px solid #6366f1 !important;
}
.menu_icon {
  padding: 0px !important;
  margin: 0px !important;
}
.share_icon_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 40px !important;
  margin-top: 1rem !important;
  size: 20px !important;
  border: 1px solid transparent !important;
}
.share_icon_btn:hover {
  color: #6366f1 !important;
  background-color: white !important;
  border: 1px solid #6366f1 !important;
}

.edit_icon_btn {
  background-color: #6366f1 !important;
  color: white !important;
  border-radius: 40px !important;

  size: 20px !important;
  border: 1px solid transparent !important;
}
.edit_icon_btn:hover {
  color: #6366f1 !important;
  background-color: white !important;
  border: 1px solid #6366f1 !important;
}
