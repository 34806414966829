.poster_details {
  margin-top: 10px;
}
.send_btn_Col {
  text-align: center;
}
.send_btn {
  background-color: #6366f1 !important;
  border-color: #6366f1 !important;
  color: white !important;
  width: 50%;
  padding: 8px 40px !important;
  font-size: 20px !important;

  text-transform: uppercase !important;
  font-weight: bold !important;
  letter-spacing: 0.1px !important;
  border: solid 1px #6366f1;
}
.send_btn:hover {
  background-color: white !important;
  color: black !important;
  border-color: rgb(0, 0, 0) !important;
  border-style: solid !important;
  border-width: 1px;
}
.amplify-image profile_pic {
  height: 300px !important;
  width: 300px !important;
  overflow: hidden !important;
}

.non_exist_user_link {
  font-size: 25px;
}

.non_exist_user {
  color: #6366f1;
}
.non_exist_user:hover {
  color: black;
}
.non_id_link {
  text-align: center;
  padding-top: 20px;
}
/* .emp_id_col{
    border-right: black;
    border-style: solid;
    border-top: none;
    border-bottom: none;
    border-left: none;
  } */


.non_exist_user_btn {
  background-color: white !important;
  border: #6366f1 solid 2px;
  font-size: larger;
  color: #6366f1;
}
.non_exist_user_btn:hover {
  background-color: #6366f1 !important;
  color: black !important;
  border: transparent !important;
}
.create_link_btn {
  background-color: #6366f1 !important;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 25px;
  font-weight: bold;
  color: white;
}
.create_link_btn:hover {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: black;
}
.create_link_Container {
  text-align: center;
  margin-top: 1rem;
}
.create_Collapse_Content {
  text-align: left;
  padding: 20px;
}

.recruiter_pendning_Edit_btn {
  background-color: white !important;
  color: #6366f1;
  font-size: 20px;
  border-color: #6366f1;
  border: solid 1px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}
.recruiter_pendning_Edit_btn:hover {
  color: white !important;
  background-color: #6366f1 !important;
  border-color: transparent !important;
}
.recruiter_pendning_View_btn {
  margin-top: 10px;
  background-color: white !important;
  color: #6366f1;
  font-size: 20px;
  border-color: #6366f1;
  border: solid 1px;
  font-weight: 600;
}

/* #justify-tab-example-tab-Declined {
  font-size: 20px;
} */
#justify-tab-example-tab-pending {
  font-size: 20px;
}
#justify-tab-example-tab-completed {
  font-size: 20px;
}
#justify-tab-example-tab-accepted {
  font-size: 20px;
}
#justify-tab-example-tab-rejected {
  font-size: 20px;
}
#justify-tab-example-tab-declined {
  font-size: 20px;
}
#justify-tab-example-tab-rejected:hover {
  color: #6366f1 !important;
}
#justify-tab-example-tab-declined:hover {
  color: #6366f1 !important;
}
#justify-tab-example-tab-completed:hover {
  color: #6366f1 !important;
}
#justify-tab-example-tab-accepted:hover {
  color: #6366f1 !important;
}
#justify-tab-example-tab-pending:hover {
  color: #6366f1 !important;
}

.btn-size {
  width: 90% !important;
}
.respond_btns {
  padding-right: 10px;
}
.edit {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
