.interview_heading_txt {
  background-color: #595959;
  height: 85px;
  text-align: center;
  color: white;
}
.interview_page_body {
  margin-top: 1rem;
}
.interview_heading_txt h2 {
  margin-top: 1.5rem;
  color: white;
}

#justify-tab-example-tab-pending {
  font-size: 20px;
}
#justify-tab-example-tab-pending:hover {
  color: orange;
}
#justify-tab-example-tab-completed {
  font-size: 20px;
}
#justify-tab-example-tab-completed:hover {
  color: orange;
}
#justify-tab-example-tab-rejected {
  font-size: 20px;
}
#justify-tab-example-tab-rejected:hover {
  color: orange;
}
#justify-tab-example-tab-Declined:hover {
  color: orange !important;
}
#justify-tab-example-tab-Declined {
  font-size: 20px;
}

.pendning_card_view_btn {
  background-color: white;
  color: orange;
  border: 1px solid orange;
  box-shadow: none;
}
.pendning_card_view_btn:hover {
  background-color: orange !important;
  color: white;
  border: 1px solid orange;
  box-shadow: none;
}
/* .company_logo img{
   

} */
.btn-size {
  width: 90%;
}
.respond_btns {
  padding-right: 10px;
}
.accept {
  color: rgb(255, 255, 255);
  background-color: green;
}

.decline {
  color: red;
}
