.new_card_container {
  margin-top: 1rem !important;
}
.new_full_card_body {
  border: 1px solid #cdcdcd !important;
  border-radius: 8px !important;
  /* margin-top: 1rem !important; */
  margin-bottom: 1rem !important;
}
.card_top_bar {
  background-color: #f4f5f7 !important;
  font-size: 24px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.new_card_bottom_bar {
  background-color: #ffffff;
  font-size: 24px !important;
  margin-top: 0px !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top: 1px solid #cdcdcd !important;
}

.new_card_content {
  margin-top: 0px !important;
  padding: 10px 0px;
  margin-bottom: 0px;
}
.top_bar_item_3 {
  text-align: right;
}

.new_content_area_col_2 {
  text-align: left !important;
  padding: 10px 0px;
}
.new_content_area_col_2 ul li {
  list-style: none;
  padding-bottom: 6px;
  padding-top: 6px;
}
.new_content_area_col_2 ul {
  padding-left: 0px !important;
}

.new_content_area_col_3 {
  text-align: left !important;
  padding: 10px 0px;
}
.new_content_area_col_3 ul li {
  list-style: none;
  padding-bottom: 6px;
  padding-top: 6px;
}
.new_content_area_col_3 ul {
  padding: 0px;
}

.bookmark_icon {
  font-size: 90px !important;
  color: white;
  border-radius: 10px;
}
.container_2 {
  margin: 0px !important;
  padding: 0px !important;
  background-color: black;
  height: 292px;
  text-align: center;
}
.bottom_card_col_2 {
  text-align: right;
}
.view_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.view_icon_btn:hover {
  color: #6366f1 !important;
}
.share_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.share_icon_btn:hover {
  color: #6366f1 !important;
}
.flag_icon_btn {
  color: black !important;
  border-radius: 10px;
}
.flag_icon_btn:hover {
  color: #6366f1 !important;
}
.skill_tags {
  color: #6366f1;
  font-size: 20px;
  font-style: italic;
}

.icon_colour {
  color: #6366f1 !important;
  margin-right: 4px;
  font-size: 22px !important;
}

/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .company_avatar {
    margin-left: 75px !important;
  }
  .top_bar_item_1 {
    font-size: 25px;
  }
  .top_bar_item_3 {
    font-size: 15px;
  }
  .bottom_card_col_2 {
    text-align: center !important;
  }
  .new_content_area_col_2 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_2 ul li {
    font-size: 18px !important;
  }
  .new_content_area_col_3 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_3 ul li {
    font-size: 18px !important;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .company_avatar {
    margin-left: 75px !important;
  }
  .top_bar_item_1 {
    font-size: 25px;
  }
  .top_bar_item_3 {
    font-size: 15px;
  }
  .bottom_card_col_2 {
    text-align: center !important;
  }
  .new_content_area_col_2 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_2 ul li {
    font-size: 18px !important;
  }
  .new_content_area_col_3 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_3 ul li {
    font-size: 18px !important;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .company_avatar {
    margin-left: 75px !important;
  }
  .top_bar_item_1 {
    font-size: 25px;
  }
  .top_bar_item_3 {
    font-size: 15px;
  }
  .bottom_card_col_2 {
    text-align: center !important;
  }
  .new_content_area_col_2 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_2 ul li {
    font-size: 18px !important;
  }
  .new_content_area_col_3 {
    text-align: left !important;
    padding: 0px 0px;
  }
  .new_content_area_col_3 ul li {
    font-size: 18px !important;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .company_avatar {
    margin-left: 0px !important;
  }
  .top_bar_item_1 {
    font-size: 25px;
  }
  .top_bar_item_3 {
    font-size: 20px;
  }
  .bottom_card_col_2 {
    text-align: right !important;
  }
  .new_content_area_col_2 {
    text-align: left !important;
    padding: 10px 0px;
  }
  .new_content_area_col_2 ul li {
    font-size: 18px !important;
  }
  .new_content_area_col_3 {
    text-align: left !important;
    padding: 10px 0px;
  }
  .new_content_area_col_3 ul li {
    font-size: 18px !important;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .company_avatar {
    margin-left: 0px !important;
  }
  .top_bar_item_1 {
    font-size: 25px;
  }
  .top_bar_item_3 {
    font-size: 20px;
  }
  .bottom_card_col_2 {
    text-align: right !important;
  }
  .new_content_area_col_2 {
    text-align: left !important;
    padding: 10px 0px;
  }
  .new_content_area_col_2 ul li {
    font-size: 18px !important;
  }
  .new_content_area_col_3 {
    text-align: left !important;
    padding: 10px 0px;
  }
  .new_content_area_col_3 ul li {
    font-size: 18px !important;
  }
}
