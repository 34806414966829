.Recom_Job_text {
  font-size: 30px;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
  color: rgb(0, 0, 0);
}
.job_list_card {
  background-color: #004d54;
  color: rgb(255, 254, 254);
  border-radius: 20px;
  padding: 10px;
  margin: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.job_description {
  color: white;
  padding: 12px 12px 12px 12px;
}

.apply_button {
  background-color: white !important;
  color: black;
  border-radius: 10px;
}
.home_img {
  position: fixed;
}
.nav_bar {
  background-color: #004d54 !important;
}

.apply_button {
  background-color: #6366f1 !important;
  border-color: #6366f1 !important;
  color: black !important;
  width: 100%;
  padding: 8px 40px !important;
  font-size: 20px !important;
  font-style: italic !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  letter-spacing: 0.1px !important;
}
.apply_button:hover {
  background-color: white !important;
  color: black !important;
  border-color: white !important;
}
.footer {
  position: static;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer_bg {
  background-color: #004d54;
}

/*------------HOME----------*/

.home_txt {
  font-size: 56px;
  text-align: left;
  margin: 20px;
  font-family: Roboto;
  text-transform: capitalize;
  letter-spacing: 0;
  line-height: 59px;
  font-weight: 700;
  text-align: center;
}

.home_page_heading_container {
  background-color: white !important;
  border-radius: 12px;
}
.total_home_pg {
  /* height: 900px; */
  background-color: white;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.home_page_img {
  border-radius: 8px;
}
.home_btn_hire {
  background-color: #6366f1;
  padding: 18px 30px;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 20px;
  margin-top: 0rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.home_details_txt {
  font-size: 20px;
  margin-left: 25px;
  margin-bottom: 4rem;
  text-align: center;
}
.home_btn_signup {
  background-color: white;
  padding: 18px 30px;
  border-radius: 5px;
  border: 1px solid #6366f1;
  color: #6366f1;
  font-size: 20px;
  margin-top: 0rem;
}
.home_btn_signup:hover {
  border: 1px solid #6366f1;
  color: #6366f1;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.home_login_btn {
  padding: 8px 20px;
  margin-top: 1rem;
}
.video_container-Col {
  border-radius: 20px;
}
.recruiter_col_txt h2 {
  margin-bottom: 1rem;
  color: #6366f1;

  font-weight: normal;
}
.employee_col_txt h2 {
  margin-bottom: 1rem;
  color: #6366f1;
  font-weight: normal;
}
.recruiter_col_txt h4 {
  margin-bottom: 2rem;
}
.employee_col_txt h4 {
  margin-bottom: 2rem;
}
.employee_row {
  margin-top: 2rem;
}
.three_steps {
  margin-top: 2rem;
  padding: 6px 12px;
}
.step_txt {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: center;
}
.step_details_txt {
  font-size: 20px;
  text-align: left;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0px;
}
.step_details_icon {
  font-size: 20px;
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 0px;
  padding-right: 0px;
}
.about_details_txt {
  margin-left: 1rem;
}
.details_txt_last {
  margin: 0px;
}
/* .bullet_list{
    margin-right: 8px;
    color: #6366f1;
    margin-top: 5px;

} */
.orange_txt {
  color: #6366f1;
  font-weight: 500;
}
.learn_more_txt a {
  text-decoration: none;
  color: #6366f1;
  margin-left: 0.75rem;
}

/*------------HOME----------*/
