/*--------------Home-----------------*/
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .home_txt {
    font-size: 40px !important ;
    line-height: 40px !important;
    text-align: center !important;
  }
  .home_page_heading_container {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .home_login_btn {
    text-align: center;
  }
  .home_gif_img {
    display: block !important;
  }
  .recruiter_steps_row {
    text-align: center;
  }
  .employee_row {
    text-align: center;
  }
  .step_txt {
    text-align: center !important ;
    margin-bottom: 1rem !important;
    margin-top: 1rem;
  }
  .step_details_txt {
    text-align: left !important;
  }
  .employee_steps_img {
    display: none;
  }
  .recruiter_col_txt h2 {
    font-size: 30px !important;
  }
  .employee_col_txt h2 {
    font-size: 30px;
  }
  .empty_space {
    display: none;
  }
  .home_btn_hire {
    margin-bottom: 1rem !important;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .home_txt {
    font-size: 40px !important ;
    line-height: 40px !important;
    text-align: center !important;
  }
  .home_page_heading_container {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .home_login_btn {
    text-align: center;
  }
  .home_gif_img {
    display: block !important;
  }
  .recruiter_steps_row {
    text-align: center;
  }
  .employee_row {
    text-align: center;
  }
  .step_txt {
    text-align: center !important ;
    margin-bottom: 1rem !important;
    margin-top: 1rem;
  }
  .step_details_txt {
    text-align: left !important;
  }
  .employee_steps_img {
    display: none;
  }
  .recruiter_col_txt h2 {
    font-size: 30px !important;
  }
  .employee_col_txt h2 {
    font-size: 30px;
  }
  .empty_space {
    display: none;
  }
  .home_btn_hire {
    margin-bottom: 1rem !important;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .home_txt {
    font-size: 40px !important ;
    line-height: 40px !important;
    text-align: center !important;
  }
  .home_page_heading_container {
    margin-top: 20px !important;
    margin-bottom: 20px;
  }
  .home_login_btn {
    text-align: center;
  }
  .home_gif_img {
    display: block !important;
  }
  .recruiter_steps_row {
    text-align: center;
  }
  .employee_row {
    text-align: center;
  }
  .step_txt {
    text-align: center !important ;
    margin-bottom: 1rem !important;
    margin-top: 1rem;
    font-size: 11px !important;
  }
  .step_details_txt {
    text-align: center !important;
  }
  .employee_steps_img {
    display: block;
  }
  .employee_steps_img_mob {
    display: none;
  }

  .recruiter_col_txt h2 {
    font-size: 30px !important;
  }
  .employee_col_txt h2 {
    font-size: 30px;
  }
  .home_btn_hire {
    margin-bottom: 0 !important;
  }
  .recruiter_col_img img {
    /* margin-top: 150px; */
  }
  .employee_steps_img img {
    /* margin-top: 150px; */
  }
  .empty_space {
    display: inline-block;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .home_txt {
    font-size: 56px !important;
    text-align: center !important;
    margin: 20px !important;
    font-family: Roboto !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    line-height: 59px !important;
    font-weight: 700 !important;
    margin-top: 5rem !important;
  }
  .home_gif_img {
    display: block !important;
  }
  .home_btn_hire {
    margin-bottom: 0 !important;
  }
  .step_txt {
    text-align: left !important ;
    margin-bottom: 3rem !important;
    margin-top: 0 !important ;
    font-size: 20px !important;
  }
  .employee_steps_img {
    display: block;
  }
  .employee_steps_img_mob {
    display: none;
  }
  .step_details_txt {
    text-align: left !important;
  }
  .empty_space {
    display: inline-block;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .home_txt {
    font-size: 56px !important;
    text-align: left !important;
    margin: 20px !important;
    font-family: Roboto !important;
    text-transform: capitalize !important;
    letter-spacing: 0 !important;
    line-height: 59px !important;
    font-weight: 700 !important;
    margin-top: 5rem !important;
    text-align: center !important;
  }
  .home_gif_img {
    display: block !important;
  }
  .step_txt {
    text-align: left !important ;
    margin-bottom: 3rem !important;
    margin-top: 0 !important ;
    font-size: 20px !important;
  }
  .employee_steps_img {
    display: block;
  }

  .step_details_txt {
    text-align: left !important;
  }
  .home_btn_hire {
    margin-bottom: 0 !important;
  }
  .empty_space {
    display: inline-block;
  }
}
/*--------------Home-----------------*/

/*--------------InterView-----------------*/

/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  #justify-tab-example-tab-pending {
    font-size: 15px !important;
  }
  #justify-tab-example-tab-completed {
    font-size: 15px !important;
  }
  #justify-tab-example-tab-rejected {
    font-size: 15px !important;
  }
  #justify-tab-example-tab-declined {
    font-size: 15px !important;
  }
  .create_link_btn {
    font-size: 20px;
  }
  .emp_status_col {
    margin-top: 0px;
  }
  .user_profile_details {
    font-size: 15px;
  }
  .back_btn {
    display: none;
  }
  .share_btn {
    margin-top: 0px;
    width: 90%;
  }
  .resume_btn {
    width: 90%;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  #justify-tab-example-tab-pending {
    font-size: 20px !important;
  }
  #justify-tab-example-tab-completed {
    font-size: 20px !important;
  }
  #justify-tab-example-tab-rejected {
    font-size: 20px !important;
  }
  #justify-tab-example-tab-declined {
    font-size: 20px !important;
  }
  .home_btn_hire {
    margin-bottom: 0 !important;
  }
  .create_link_btn {
    font-size: 25px;
  }
  .share_btn {
    margin-top: 2rem;
  }
  .back_btn {
    display: block;
  }
  .emp_status_col {
    margin-top: 8rem;
  }
  .user_profile_details {
    font-size: 17px;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
}

/*--------------InterView-----------------*/
