.share_btn {
  font-size: 22px;
  background-color: #6366f1;
  color: white;
  margin-top: 2rem;
  width: 100% !important;
}
.share_candidate_icon_btn {
  color: white !important;
  border-radius: 10px;
  margin-right: 0.5rem !important;
}
.share_candidate_icon_btn:hover {
  color: #6366f1 !important;
}
