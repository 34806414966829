.card {
  text-align: center;
  padding: 6px 12px;
}
.profile_info_page {
  padding: 6px 12px;
}
.profile_pic_btn {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: white !important;
  padding: 9px 12px;
  color: orange;
  border-color: orange;
  border: 1px solid !important;
}
.amplify-image profile_pic {
  height: 300px !important;
  width: 300px !important;
  overflow: hidden !important;
}
.profile_pic_btn:hover {
  background-color: orange !important;
  color: white !important;
  border: orange !important;
  border: 1px solid !important;
}
.profile_pic_btn_html {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: white !important;
  border: 1px solid orange !important;
  padding: 9px 12px;
  border-radius: 5px;
  color: orange;
}
.profile_pic_btn_html:hover {
  background-color: orange !important;
  color: white !important;
  border: orange !important;
  border: 1px solid !important;
}
.continue_btn {
  background-color: white !important;
  border: none;
  color: orange;
  border: 1px solid orange;
}
.continue_btn:hover {
  background-color: orange !important;
  color: white;
  border: 1px solid orange;
}
.save_btn {
  text-align: center;
}
.profile_details {
  border: 1px solid;
  border-color: gainsboro;
  border-radius: 6px;
  background-color: white;
  padding: 6px 12px;
}
.select_fresher {
  padding: 6px 12px;
}
.select_experienced {
  padding: 6px 12px;
}
.error_msg {
  color: red !important;
  margin: 0px !important;
}
.profile_editInfo_text {
  background-color: black;
  height: 85px;
  text-align: center;
}
.profile_editInfo_text h2 {
  margin-top: 1.5rem;
}
.profile_pic {
  border-radius: 8px;
}
.profile_pic_card {
  padding: 4px 35px;
}
