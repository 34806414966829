.Recom_Job_text {
  font-size: 30px;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
  color: rgb(0, 0, 0);
}

.apply_button {
  background-color: white !important;
  color: black;
  border-radius: 10px;
}
.home_img {
  position: fixed;
}
.nav_bar {
  background-color: #004d54 !important;
}

.apply_button {
  background-color: #6366f1 !important;
  border-color: #6366f1 !important;
  color: black !important;
  width: 100%;
  padding: 8px 40px !important;
  font-size: 20px !important;
  font-style: italic !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  letter-spacing: 0.1px !important;
}
.home_details_txt {
  font-size: 20px;
  margin-left: 25px;
  margin-bottom: 2rem;
}
.apply_button:hover {
  background-color: white !important;
  color: black !important;
  border-color: white !important;
}
.footer {
  position: static;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer_bg {
  background-color: #004d54;
}

/*------------HOME----------*/

.home_txt {
  font-size: 56px;
  text-align: left;
  margin: 20px;
  font-family: Roboto;
  text-transform: capitalize;
  letter-spacing: 0;
  line-height: 59px;
  font-weight: 700;
}

.home_page_heading_container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home_page_img {
  border-radius: 8px;
}

.video_container-Col {
  border-radius: 20px;
}
.recruiter_col_txt h2 {
  margin-bottom: 1rem;
  color: #6366f1;
  text-align: center;
}
.employee_col_txt h2 {
  margin-bottom: 1rem;
  color: #6366f1;
}
.recruiter_col_txt h4 {
  margin-bottom: 2rem;
  text-align: center;
}
.employee_col_txt h4 {
  margin-bottom: 2rem;
}
.employee_row {
  margin-top: 2rem;
}
.three_steps {
  margin-top: 2rem;
}
.step_txt {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: center;
}
.step_details_txt {
  font-size: 20px;
  text-align: left;
}
.orange_txt {
  color: #6366f1;
  font-weight: 500;
}
.learn_more_txt a {
  text-decoration: none;
  color: #6366f1;
  margin-left: 0.75rem;
}

/*------------HOME----------*/
