.bg_color {
  background-color: #f4f2f7;
  height: 900px;
}
.create_job_grid {
  padding-right: 16px !important;
}
.create_job_title {
  font-size: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
}
.create_job_context {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 8px !important;
  background-color: white;
  height: auto;
}
.textarea {
  resize: none;
}
.txt_area_padding {
  padding: 6px 6px !important;
  border-color: #c4c4c4 !important;
  border-radius: 4px !important;
  width: 100%;
  height: 100px !important;
}
.error {
  color: red !important;
  margin: 0px !important;
}
.save_continue_btn_grid {
  text-align: center;
}
.save_continue_btn {
  background-color: #6366f1 !important;
  color: white !important;
}
.box_styles {
  width: 100%;
}
.avatar_style_pic_upload {
  padding-bottom: 0px !important;
}
.profile_upload_btn {
  background-color: #6366f1 !important;
  color: #ffffff !important;
  top: 75px;
}
.btn_align_center {
  text-align: center;
}
.set_avatar_border {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
/*VERY-SMALL-MOBILE*/
@media only screen and (min-width: 200px) {
  .hide_mobile {
    display: none;
  }

  .bg_color {
    height: auto !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
    top: 0px;
  }
  .avatar_style_pic_upload {
    margin-left: 65px;
  }
  .set_avatar_border {
    border: none;
  }
}
/*SMALL-MOBILE*/
@media only screen and (min-width: 320px) {
  .hide_mobile {
    display: none;
  }

  .bg_color {
    height: auto !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
    top: 0px;
  }
  .avatar_style_pic_upload {
    margin-left: 65px;
  }
  .set_avatar_border {
    border: none;
  }
}
/*BIG-MOBILE*/
@media only screen and (min-width: 480px) {
  .hide_mobile {
    display: none;
  }

  .bg_color {
    height: auto !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
    top: 0px;
  }
  .avatar_style_pic_upload {
    margin-left: 65px;
  }
  .set_avatar_border {
    border: none;
  }
}
/*IPAD AND TABLETS*/
@media only screen and (min-width: 768px) {
  .hide_mobile {
    display: none;
  }

  .bg_color {
    height: 800px !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
    top: 0px;
  }
  .avatar_style_pic_upload {
    margin-left: 65px;
  }
  .set_avatar_border {
    border: none;
  }
}
/*LAPTOPS*/
@media only screen and (min-width: 992px) {
  .hide_mobile {
    display: block;
  }

  .bg_color {
    height: 900px !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
    top: 75px;
  }
  .avatar_style_pic_upload {
    margin-left: 25px;
    margin-right: auto;
  }
  .set_avatar_border {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  }
}
/*BIG-MONITORS*/
@media only screen and (min-width: 1200px) {
  .hide_mobile {
    display: block;
  }

  .bg_color {
    height: 900px !important;
  }
  .profile_upload_btn {
    background-color: #6366f1 !important;
    color: #ffffff !important;
  }
  .avatar_style_pic_upload {
    margin-left: 25px;
    margin-right: auto;
  }
  .set_avatar_border {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  }
}
