.mt-5 {
  margin-top: 0px !important;
  margin-bottom: 1rem !important;
}

.text-end {
  text-align: center !important;
}
.feedback_Q_content {
  font-size: 20px;
  font-weight: bold;
}
.text-center {
  margin-bottom: 1rem;
}
.comment_title_txt {
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-bottom: 1rem !important;
}
.comments_submit_btn {
  color: #ffffff !important;
 
  border: 1px solid #6366f1 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
.feedback_title_txt {
  font-size: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: bold;
  margin-bottom: 0rem !important;
}
.feedback_signin_txt {
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: bold;
  color: #6366f1;
  margin-bottom: 0rem !important;
}
/* .toggle_btn_color {
  color: #6366f1 !important;
}
.toggle_btn_yes {
  color: #6366f1 !important;
}

.toggle_btn_maybe {
  color: #6366f1 !important;
}
.toggle_btn_no {
  color: #6366f1 !important;
} */
.feedback_textarea {
  padding: 10px;
  border-radius: 6px;
  height: 100px !important;
}
.feedback_textarea:hover {
  border-color: #6366f1;
}

.comment_txt{
  font-size: 22px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0px !important;
}
.feedback_form{
  padding: 0px !important;
}
.comment_btn_row{
  text-align: right;
}