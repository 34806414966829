.aboutus_text {
  text-align: left;
}
.aboutus_text h2 {
  color: #6366f1;
  font-size: 35px;
  text-align: center;

  font-weight: bold;
}
.about_us_grid {
  padding-left: 0px !important;
}
