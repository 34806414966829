.fixed_bg_color {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.share_container {
  padding: 20px 25px 20px 25px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.share_feedback_container {
  padding: 20px 25px 20px 25px;
}

.avatar_grid {
  padding: 12px 12px !important;
}
.avatar_resize {
  width: 100% !important;
}
.share_profile_btn {
  border-radius: 8px !important;
  border-color: #6366f1 !important;
  color: white !important;
  padding: 6px 0px !important;
  border: 2px solid #6366f1 !important ;
  font-size: 15px !important;
  font-weight: bold !important;
  background-color: #6366f1 !important;
}
.share_profile_btn:hover {
  color: white !important;
  border: 2px solid #6366f1 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.share_profile_resume_btn {
  border-radius: 8px !important;
  border-color: #6366f1 !important;
  color: #6366f1 !important;
  padding: 6px 0px !important;
  border: 2px solid !important;
  font-size: 15px !important;
  font-weight: bold !important;
}
.share_profile_resume_btn:hover {
  background-color: #6366f1 !important;
  color: white !important;
  border: 2px solid #6366f1 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.share_profile_btn_grid {
  padding: 12px 12px !important;
}
.share_profile_icon_btn {
  font-size: 15px;
  margin-right: 0.5rem;
}
.resume_download_icon_btn {
  font-size: 15px;
  margin-right: 0.5rem;
}
.share_name_txt {
  font-size: 25px;
  font-weight: bold;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
.social_icon_grid {
  padding: 24px 24px !important;
  text-align: center !important;
}
.social_icons {
  margin-left: 0.75rem;
}
.share_info_icons {
  color: #6366f1 !important;
  font-size: 30px !important;
  margin-right: 0.5rem;
}
.share_info_txt {
  font-size: 18px;
}
.card_header_txt {
  font-size: 20px !important;
}
.card_style {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 0.5rem !important;
  cursor: pointer;
}
.card_style:hover {
  border-color: #6366f1;
}

.card_img_logo {
  object-fit: contain !important;
}
.interview_title_txt {
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-left: 6px;
  margin-bottom: 1rem;
}

.video_play_btn {
  background-color: white !important;
  color: #6366f1 !important;
  border: 2px solid #6366f1 !important;
  border-radius: 50px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.video_play_btn:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  background-color: #6366f1 !important;
  color: white !important;
}
.play_btn_style {
  font-size: 50px !important;
}
.job_details_summary_txt {
  font-size: 20px;
}
.job_detail_grid {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 22px 25px !important;
  border-radius: 8px;
}
.job_title_txt {
  font-size: 18px;
  font-weight: bold;
}
.interview_title_grid {
  margin-bottom: 0.5rem !important;
}
.job_status_chip {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 12px;
  text-transform: uppercase;
  color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-left: 0.5rem;
}

.pending {
  border: 2px solid #6366f1;
  background-color: #6366f1;
}

.completed {
  border: 2px solid #0e7e1b;
  background-color: #0e7e1b;
}
.declined {
  border: 2px solid #840000;
  background-color: #840000;
}
.job_reference_id {
  font-size: 18px;
  margin-left: 0.5rem;
  color: grey;
}
.job_reference_id a {
  color: #6366f1 !important;
}
.job_description_txt {
  font-size: 16px;
}
.job_skills_txt {
  font-size: 16px;
}
.job_interview_by_txt {
  font-size: 16px;
  margin-top: 0.5rem;
  color: #6366f1;
  font-weight: bold;
}
.job_interview_by_context {
  font-size: 16px;
}
.interview_edit_icon_grid {
  text-align: right;
}
.interview_edit_icon {
  background-color: #6366f1 !important;
  color: white !important;
}

.interview_edit_icon:hover {
  color: #6366f1 !important;
  background-color: white !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.feedback_count_group {
  margin-top: 1rem;
}
.profile_resume_link {
  color: #6366f1 !important;
}
.profile_resume_link:hover {
  color: white !important;
}
