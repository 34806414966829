.create_profile_stepper {
  margin-top: 2.5rem !important;
}
.next_btn {
  background-color: #6366f1 !important;
  color: white !important;
}
.back_btn {
  border: 1px solid gray !important;
}
.back_btn:hover {
  border: 1px solid #6366f1 !important;
  color: #6366f1 !important;
  background-color: white !important;
}
.back_btn:focus {
  color: black !important;
}
.back_btn:active {
  color: #6366f1 !important;
}
.back_btn:disabled {
  border-color: gainsboro !important;
}
.personal_info_container {
  border-radius: 8px !important;
  padding-right: 70px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  margin-top: 3rem;
  background-color: white;
}
.hide {
  display: none;
}
.profile_note_instruction_txt {
  border: none !important;
}
.profile_note_instruction_txt h3 {
  text-align: center;
  margin-top: 1.5rem;
  color: #6366f1;
}
.profile_note_list_txt li {
  padding: 6px 12px;
  font-size: 18px;
  color: #6366f1;
}
.profile_note_list_txt li p {
  color: black;
  margin-bottom: 6px !important;
}
.video_info_container {
  border-radius: 8px !important;
  padding-right: 16px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  margin-top: 3rem;
  background-color: white;
}
.top_margin {
  margin-top: 2rem !important;
}
.gZWFJY {
  border: none !important;
}
.video_record_container {
  border-radius: 8px !important;
  padding-right: 16px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  margin-top: 3rem;
  background-color: white;
}
.recorder_grid {
  padding: 12px 6px 25px 6px;
  height: 500px;
}
.record_note_txt {
  color: #6366f1;
}
.gender_errror_msg {
  color: #d32f2f;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.stepper_txt {
  font-size: 20px !important  ;
}
